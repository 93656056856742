export const environment = {
    production: false,
    apiUrl: 'https://efarm.cm:8443',
    liveApiUrl: 'https://efarm.cm:8443/',
    appTitle: 'eFarm Market Place',
    apiUsername: 'payunit_sand_MO7t1fZuT',
    apiPassword: '477b4322-087a-4469-8c86-c6f64b20a7cc',
    apiKey: 'e5c4294e2ac5f2b32b95a57246f2176a86e90f94',
    mode: 'test',
    return_url: 'http://localhost:4200/payment/return',
    notify_url: 'http://localhost:9090/payments/return',
    purchaseRef: '',
    total_amount: '',
    name: 'Efarm Market Place',
    currency: 'XAF',
    image_cdn_url: 'https://awkamyaulq.cloudimg.io/v7',
    appName: 'EWeb',
    flutterwaveKey: 'FLWPUBK_TEST-22719ae7d6c9cc8a1228f5211ce96f58-X',
    companyNumber: '681763523',
    fixerKey: 'fd63b536fa83182bfc5ea0ad979f9ad3',
    exchangeRateApiKey: 'ca039b9e0c82e80097c310f6'
};
