import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BlogService } from 'src/app/_services/blog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-latest-blogs',
  templateUrl: './latest-blogs.component.html',
  styleUrls: ['./latest-blogs.component.css']
})
export class LatestBlogsComponent implements OnInit {
  loading = true;
  blogPosts = [];
  defaultImage = '/assets/images/default.jpg';
  testimonials = [];
  partners = [
    {
      name: 'K Hotel',
      image_path: 'assets/images/partners/partner-3.jpeg'
    },
    {
      name: 'Patnuc',
      image_path: 'assets/images/partners/patnuc.jpg'
    },
    {
      name: 'World Bank',
      image_path: 'assets/images/partners/worldbank.jpg'
    },
    {
      name: 'Minepia',
      image_path: 'assets/images/partners/minepia.jpg'
    },
    {
      name: 'Minader',
      image_path: 'assets/images/partners/minader.jpg'
    },
    {
      name: 'Minposte',
      image_path: 'assets/images/partners/minposte.jpg'
    },
    // {
    //   name: 'Atlaz Group',
    //   image_path: 'assets/images/partners/partner-1.jpeg'
    // },
    // {
    //   name: 'JFI-Express (Joe Cargo)',
    //   image_path: 'assets/images/partners/partner-2.jpeg'
    // },
    // {
    //   name: 'ELIBOY FORTURNE FARM',
    //   image_path: 'assets/images/partners/partner-4.jpeg'
    // },
    // {
    //   name: 'SMS Messagerie',
    //   image_path: 'assets/images/partners/partner-5.jpeg'
    // },
    // {
    //   name: 'ANTIC',
    //   image_path: 'assets/images/partners/antic.jpg'
    // },
    // {
    //   name: 'Flutterwave',
    //   image_path: 'assets/images/partners/flutterwave-logo.jpg'
    // },
    // {
    //   name: 'LALAS',
    //   image_path: 'assets/images/partners/lalas.png'
    // },
    // {
    //   name: 'MobileMoney',
    //   image_path: 'assets/images/partners/MobileMoney.jpg'
    // },
    // {
    //   name: 'Orange',
    //   image_path: 'assets/images/partners/orange.png'
    // },
    // {
    //   name: 'satagro',
    //   image_path: 'assets/images/partners/satagro.JPG'
    // },
    // {
    //   name: 'SNS Logistics',
    //   image_path: 'assets/images/partners/sns_logistics.jpg'
    // },
    {
      name: 'NONODIA GROUP',
      image_path: 'assets/images/partners/nondia-group.jpeg'
    }
  ];

  constructor(private blogService: BlogService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.loadBlogPosts();
    this.testimonials = [
      {
        url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/CPB9USD9jZ8'),
        content: '',
        author: 'Daral Addison',
        role: 'Founder/CEO Torpedopot.com',
        externalUrl: this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/CPB9USD9jZ8')
      },
      {
        url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/WvDgt_Wmu7w'),
        content: '',
        author: 'Rebecca MBIEH',
        role: 'Legal Counsel, Cameroon',
        externalUrl: this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/WvDgt_Wmu7w')
      },
      {
        url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/GaPY27MXMns'),
        content: '',
        author: 'Ayah W. Abine (Coach TIL)',
        role: 'Productivity & Business Leadership Coach/Consultant, Cameroon',
        externalUrl: this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/GaPY27MXMns')
      },
      {
        url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/Bf3vRAP7RUs'),
        content: '',
        author: 'Ngwang Jonathan',
        role: 'Mondial Hotel, Cameroon',
        externalUrl: this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/Bf3vRAP7RUs')
      },
      {
        url: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/E7OkDvjEoIE'),
        content: '',
        author: 'Killah Caleb',
        role: 'Poultry Farmer, Cameroon',
        externalUrl: this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/E7OkDvjEoIE')
      }
    ];
    this.testimonials = this.testimonials.sort(() => Math.random() - Math.random()).slice(0, 3);
    this.partners = this.partners.sort(() => Math.random() - Math.random()).slice(0, 6);
    // this.partners = this.partners.slice(0, 6).reverse();
  }

  loadBlogPosts() {
    this.blogService.loadLatest().subscribe((response) => {
      this.blogPosts = response;
      this.loading = false;
    });
  }

  getImageSrc(imagePath) {
    return imagePath !== '' && imagePath !== null ?
    environment.image_cdn_url + '/' + imagePath + '?func=cropfit&width=366&dpr=1.75&quality=100' : '/assets/images/blog-img1_1.jpg';
  }
}
